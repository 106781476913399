//React
import React from 'react'

//Gatsby
import { Link } from 'gatsby'

//Icons
import { FaArrowRight } from 'react-icons/fa'

const Snippet = (props) => {
  const { data } = props
  return (
    <div class={`gradient-callout-sunset py-3`}>
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="display-3 white-1 text-center">
                {data.cta ? data.cta : 'Promote your'}{' '}
                {data.noun ? data.noun : ''}
                {/* Discover &amp; remix social content to promote your {data.noun} */}
              </h3>
              <p class="lead font-weight-light text-center white-1">
                {data.description}
              </p>
              <Link to={data.path} class="btn btn-cta-outline">
                See {data.adjective} templates{' '}
                <FaArrowRight class="btn-arrow" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Snippet
